


































































import { Component, Vue } from 'vue-property-decorator'
import { calendarStore } from '@/store'
import { ViewMode } from '@/store/modules/calendar/types'

@Component({
  components: {
    SearchBar: () => import('@/components/HeaderBar/SearchBar/index.vue'),
    BaseBtn: () => import('@/components/UI/BaseBtn.vue'),
    IconChevronRight: () => import('@/components/UI/icons/IconChevronRight.vue'),
    IconChevronLeft: () => import('@/components/UI/icons/IconChevronLeft.vue'),
  },
})
export default class CalendarHeaderBar extends Vue {
  private changeViewMode (mode: ViewMode) {
    this.sendGoal(mode)
    calendarStore.actions.changeViewMode(mode)
  }

  get viewMode () {
    return calendarStore.state.viewMode
  }

  private btnTextColor (mode: ViewMode): string {
    return mode === this.viewMode ? 'td-blue-3' : 'td-gray-5'
  }

  private btnColor (mode: ViewMode): string {
    return mode === this.viewMode ? 'td-gray-light' : 'td-gray-3'
  }

  private sendGoal (mode: ViewMode): void {
    let goal = 'Календарь — Включить '
    switch (mode) {
      case 'day':
        goal += '«День»'
        break
      case 'week':
        goal += '«Неделя»'
        break
      case 'month':
        goal += '«Месяц»'
        break
      default:
        return
    }

    window.goal('Calendar', { Calendar: goal })
  }
}
